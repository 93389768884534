import React from "react";
import cx from "classnames";
import Photo from "@components/photo";
const Partners = ({ data = {} }) => {
  const { sectionTitle, logos, backgroundColor } = data;
  return (
    <section
      className={cx(
        "partners",
        "p-10",
        "py-[125px]",
        `bg-${backgroundColor.value}`,
        "relative",
        "z-0",

        "site-container"
      )}
      data-theme={backgroundColor?.title.toLowerCase()}
    >
      <div className={cx("text-center")}>
        {sectionTitle && (
          <div
            className={cx(
              "text-caption",
              "font-sansRegular",
              "tracking-[0.14em]",
              "uppercase",
              "mb-5"
            )}
          >
            {sectionTitle}
          </div>
        )}
        {logos && (
          <div
            className={cx(
              "logos",
              "inline-flex",
              "justify-center",
              "py-5",
              "border-t",
              "bg-gray",
              "border-b",
              "border-[#C8C8C8]",
              "w-auto",
              "items-center",
              "opacity-100",
              "grid",
              "grid-cols-4",
              "max-w-[500px]",
              "mx-auto"
            )}
          >
            {logos.map((logo, idx) => (
              <div
                key={idx}
                className={cx("mx-4", "col-span-2", "md:col-span-1")}
              >
                <div
                  className={cx("max-w-[100px]", "mx-auto", "my-2", "relative")}
                >
                  {logo.url && (
                    <a href={logo.url} rel="noreferrer" target="_blank">
                      <span className="sr-only">Link to {logo.url}</span>
                      <Photo photo={logo.image} width={1200} layout="static" />
                    </a>
                  )}
                  {!logo.url && (
                    <Photo photo={logo.image} width={1200} layout="intrinsic" />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Partners;
